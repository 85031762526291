export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOG_OUT = 'LOG_OUT';

export const GET_BOOKS = 'GET_BOOKS';
export const CREATE_BOOK = 'CREATE_BOOK';
export const DELETE_BOOK = 'DELETE_BOOK';
export const UPDATE_BOOK = 'UPDATE_BOOK';

export const GET_WISHES = 'GET_WISHES';
export const CREATE_WISH = 'CREATE_WISH';
export const DELETE_WISH = 'DELETE_WISH';
export const UPDATE_WISH = 'UPDATE_WISH';

export const GET_MATCHES = 'GET_MATCHES';
export const UPDATE_MATCH_WITH_SENT_MESSAGE = 'UPDATE_MATCH_WITH_SENT_MESSAGE';
export const UPDATE_MATCH_WITH_RECEIVED_MESSAGE = 'UPDATE_MATCH_WITH_RECEIVED_MESSAGE';

export const GET_MESSAGES = 'GET_MESSAGES';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
